import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  catchError,
  finalize,
  Observable,
  of,
  retry,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { JwtHelperService } from '@auth0/angular-jwt';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { LocalStorageService } from './services/local-storage.service';
import { KeywordsAndConstants } from '../core/keywords-and-constants';
import { AdminLoginService } from './services/admin-login.service';
import { environment } from '../environments/environment';
import { GenericError } from '../models/generic-error';
import { ErrorAction } from '../enums/error-action';
import { ProfileResponseModel } from '../models/profile.model';
import { Apis } from '../core/apis';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ModalArguments } from '../models/modal-arguments';
import { DialogUtil } from '../utils/dialog-utils';
import { ModalButtonType } from '../enums/modal_button_type';
import { MessageType } from '../enums/message-type';
import { UnderMaintenanceModalComponent } from '../app/pages/under-maintenance-modal/under-maintenance-modal.component';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  // modalRef?: BsModalRef;
  private refreshTokenRequestCount: number = 0;
  private tokenBlockRequestCount: number = 0;
  private logoutDurToFailedRefreshTokenRequestThreshold: number = 3;
  private errorDialogCount: number = 0;
  private dialogCount: number = 0;
  private currentUrlsOnProgress: number = 0;
  private gettingReportFormatResponse: boolean = false;

  constructor(
    private http: HttpClient,
    private _service: LocalStorageService,
    public constants: KeywordsAndConstants,
    private modalServiceForMaintenanceModal: BsModalService,
    private _loginService: AdminLoginService,
    public router: Router,
    private location: Location,
    private loader: SpinnerVisibilityService,
    private dialog: DialogUtil
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      !this._service.getToken() &&
      !request.url.toString().includes('refreshToken')
    ) {
      request = request.clone({
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': environment.allowed_origin,
          trackingId: uuidv4(),
        }),
      });
    } else if (request.url.toString().includes('refreshToken')) {
      request = request.clone({
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': environment.allowed_origin,
          Authorization: `Bearer ${this._service.getRefreshToken()}`,
          trackingId: uuidv4(),
        }),
      });
    } else if (
      request.url.toString().includes('https://ve-auth-bucket-dev') ||
      request.url.toString().includes('https://stableone-liveness-check') ||
      request.url.toString().includes('getVerificationStatus')
    ) {
      request = request.clone({
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': environment.allowed_origin,
        }),
      });
    } else {
      request = request.clone({
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': environment.allowed_origin,
          Authorization: `Bearer ${this._service.getToken()}`,
          trackingId: uuidv4(),
        }),
      });
    }
    return this.handleRequest(next, request);
  }

  private handleRequest(next: HttpHandler, request: HttpRequest<any>) {
    this.currentUrlsOnProgress++;
    if (
      request.url.includes('getReportFormatResponse') ||
      (request.url.includes('byCategory') && this.gettingReportFormatResponse)
    ) {
      this.loader.hide();
      this.gettingReportFormatResponse = true;
    } else {
      if (this.gettingReportFormatResponse) {
        this.loader.show();
      }
    }
    return next.handle(request).pipe(
      retry(0),
      catchError((error: HttpErrorResponse) => {
        let genericError: GenericError = new GenericError();
        if (request.url.toString().includes('getVerificationStatus')) {
          genericError.errorAction = ErrorAction.HANDLED;
          let errorCode = error.error.code;
          let message = error.error.message;
          const initialState = {
            errorCode: errorCode,
            message: message,
          };
          const modalOptions: ModalOptions = {
            ignoreBackdropClick: true,
            keyboard: false,
          };
          this.modalServiceForMaintenanceModal.show(
            UnderMaintenanceModalComponent,
            { initialState, ...modalOptions }
          );
          localStorage.setItem(
            this.constants.isModalOpen,
            JSON.stringify(true)
          );
        } else if (error.error instanceof ErrorEvent) {
          throw new GenericError(0, error.error.message);
        } else {
          genericError.message = error.error.message;
          genericError.code = error.error.code;
          if (
            error.error.message != undefined &&
            error.status == HttpStatusCode.BadRequest
          ) {
            if (error.error.code == 400001) {
              genericError.errorAction = ErrorAction.HANDLED;
            }
          } else {
            if (error.status == HttpStatusCode.Unauthorized) {
              if (error.error.code == 401001) {
                if (request.url.includes('refreshToken')) {
                  this._service.logout();
                } else {
                  return this.refreshToken(next, request);
                }
              } else if (error.error.code == 401003) {
                this.tokenBlockRequestCount++;
                if (this.tokenBlockRequestCount == 1) {
                  return this.refreshToken(next, request);
                }
              } else {
              }
            } else if (error.status == HttpStatusCode.Forbidden) {
              if (error.error.code == 403001) {
                this.router.navigateByUrl('/twoFactor', {
                  state: { Access: true },
                });
              } else if (error.error.code == 403007) {
                genericError.errorAction = ErrorAction.HANDLED;
                const contextAlias = this;
                this.dialogCount++;
                if (this.dialogCount == 1) {
                  this.dialog.errorShowModal(
                    new ModalArguments(error.error.message)
                      .withButtonType(ModalButtonType.DOUBLE_BUTTON)
                      .withButtonPositiveText('Added to trusted devices')
                      .withButtonNegativeText('Resend')
                      .dontDismissNegativeButtonOnClick()
                      .withCancellable(false)
                      .withCallback({
                        buttonNegativeClicked() {
                          contextAlias.http
                            .get(Apis.sendMail)
                            .subscribe((res: any) => {
                              contextAlias.dialog.showToast(
                                res.message,
                                MessageType.SUCCESS
                              );
                              setTimeout(
                                () => (contextAlias.dialogCount = 0),
                                300
                              );
                              contextAlias.location.back();
                            });
                        },
                        buttonPositiveClicked() {
                          contextAlias.http
                            .get(Apis.refreshToken)
                            .subscribe((res: any) => {
                              if (res) {
                                contextAlias._service.backUpToken =
                                  JSON.stringify(res.token);
                                contextAlias._service.backUpRefreshToken =
                                  JSON.stringify(res.refreshToken);
                                localStorage.setItem(
                                  contextAlias.constants.LocalStorageToken,
                                  JSON.stringify(res.token)
                                );
                                localStorage.setItem(
                                  contextAlias.constants
                                    .LocalStorageRefreshToken,
                                  JSON.stringify(res.refreshToken)
                                );
                                contextAlias.dialogCount = 0;
                                contextAlias.location.back();
                              }
                            });
                        },
                        buttonSingleClicked() {},
                      })
                  );
                }
              } else if (error.error.code == 403009) {
              } else if (error.error.code == 403010) {
                genericError.errorAction = ErrorAction.HANDLED;
                const contextAlias = this;
                this.dialog.errorShowModal(
                  new ModalArguments(error.error.message)
                    .withButtonType(ModalButtonType.SINGLE_BUTTON)
                    .withButtonSingleText('Ok')
                    .withCallback({
                      buttonNegativeClicked() {},
                      buttonPositiveClicked() {},
                      buttonSingleClicked() {
                        contextAlias.router.navigateByUrl(
                          '/password/change-password',
                          {
                            replaceUrl: true,
                          }
                        );
                      },
                    })
                    .withCancellable(false)
                );
              } else if (error.error.code == 403012) {
                this.router.navigateByUrl('/twoFactor');
              }
            } else if (
              error.statusText == 'Request Entity Too Large' ||
              error.status == 413
            ) {
              genericError.errorAction = ErrorAction.HANDLED;
              this.dialog.showToast(error.error.message, MessageType.ERROR);
            } else if (error.status == HttpStatusCode.ServiceUnavailable) {
              genericError.errorAction = ErrorAction.HANDLED;
              let errorCode = error.error.code;
              let message = error.error.message;
              const initialState = {
                errorCode: errorCode,
                message: message,
              };
              const modalOptions: ModalOptions = {
                ignoreBackdropClick: true,
                keyboard: false,
              };
              this.modalServiceForMaintenanceModal.show(
                UnderMaintenanceModalComponent,
                { initialState, ...modalOptions }
              );
              localStorage.setItem(
                this.constants.isModalOpen,
                JSON.stringify(true)
              );
            } else {
              genericError.errorAction = ErrorAction.HANDLED;
              let message = 'Service Unavailable';
              const initialState = {
                errorCode: 0,
                message: message,
              };
              const modalOptions: ModalOptions = {
                ignoreBackdropClick: true,
                keyboard: false,
              };
              this.modalServiceForMaintenanceModal.show(
                UnderMaintenanceModalComponent,
                { initialState, ...modalOptions }
              );
            }
          }
        }
        return throwError(genericError);
      }),
      tap((response) => {
        if (response instanceof HttpResponse) {
          this.currentUrlsOnProgress--;
          if (this.currentUrlsOnProgress == 0) {
            if (this.gettingReportFormatResponse) {
              this.loader.hide();
              this.gettingReportFormatResponse = false;
            }
          }
        }
      })
    );
  }

  private refreshToken(next: HttpHandler, request: HttpRequest<any>) {
    this.refreshTokenRequestCount++;
    let existingUniqueId = localStorage.getItem('uniqueIdentifierId');
    return this.refreshAccessToken().pipe(
      switchMap((refreshTokenResponse: any) => {
        if (refreshTokenResponse) {
          localStorage.setItem(
            this.constants.LocalStorageToken,
            JSON.stringify(refreshTokenResponse.token)
          );
          localStorage.setItem(
            this.constants.LocalStorageRefreshToken,
            JSON.stringify(refreshTokenResponse.refreshToken)
          );
          this._service.backUpToken = JSON.stringify(
            refreshTokenResponse.token
          );
          this._service.backUpRefreshToken = JSON.stringify(
            refreshTokenResponse.refreshToken
          );
          localStorage.setItem('uniqueIdentifierId', existingUniqueId!!);
          const helper = new JwtHelperService();
          const decodedToken = helper.decodeToken(refreshTokenResponse.token);
          localStorage.setItem(this.constants.isLoginType, decodedToken.type);
          if (
            localStorage.getItem(this.constants.isLoginType) == 'MASTER_USER'
          ) {
            this._loginService
              .user()
              .subscribe((user: ProfileResponseModel) => {
                localStorage.setItem(
                  this.constants.LocalStorageUserInfo,
                  JSON.stringify(user)
                );
              });
          } else if (
            localStorage.getItem(this.constants.isLoginType) == 'USER'
          ) {
            this._loginService
              .user()
              .subscribe((user: ProfileResponseModel) => {
                localStorage.setItem(
                  this.constants.LocalStorageUserInfo,
                  JSON.stringify(user)
                );
              });
          }
          return next.handle(this.addAuthenticationToken(request));
        } else {
          return of();
        }
      }),
      catchError((error: any) => {
        this._service.logout();
        return of();
      }),
      finalize(() => {
        this.refreshTokenRequestCount = 0;
        this.tokenBlockRequestCount = 0;
      })
    );
  }

  private refreshAccessToken(): Observable<any> {
    return this.http.get(Apis.refreshToken, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': environment.allowed_origin,
        Authorization: `Bearer ${this._service.getRefreshToken()}`,
      }),
    });
  }

  private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
    if (request.url.includes('refreshToken')) {
      return request.clone({
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': environment.allowed_origin,
          Authorization: `Bearer ${this._service.getRefreshToken()}`,
        }),
      });
    } else {
      return request.clone({
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': environment.allowed_origin,
          Authorization: `Bearer ${this._service.getToken()}`,
        }),
      });
    }
  }
}

import { env } from 'process';
import { environment } from '../environments/environment';

export class Apis {
  static me = `${environment.base_url}/user/getProfile`;
  static adminLogin = `${environment.base_url}/login/email`;
  static getApplicationToLoginWith = `${environment.base_url}/application/getApplicationToLoginWith`;

  static refreshToken = `${environment.base_url}/refreshToken`;

  static byCategory = `${environment.base_url}/accessControl/byCategory`;
  static accessControl = `${environment.base_url}/accessControl`;

  static changePassword = `${environment.base_url}/password/changePassword`;
  static forgotPassword = `${environment.base_url}/password/forgotPassword`;
  static passwordConditions = `${environment.base_url}/password/conditions`;

  static sendOTP = `${environment.base_url}/password/forgotPassword/sendOtp`;
  static otpDeliveryChannel = `${environment.base_url}/enums/otpDeliveryChannel`;
  static otpDigits = `${environment.base_url}/otp/getOtpDigitsCount`;

  static getApplicationApiKeys = `${environment.base_url}/user/getApiKeys`;
  static createApplicationApiKeys = `${environment.base_url}/user/createApiKey`;
  static deActivateApplicationApiKeys = `${environment.base_url}/user/deActivateApiKey`;

  static createApiKeyForAdmin = `${environment.base_url}/user/application/createApiKey `;
  static deactivateApiKeysForAdmin = `${environment.base_url}/user/application/deActivateApiKey `;

  static getMasterUserList = `${environment.base_url}/getUsers`;
  static createMasterUser = `${environment.base_url}/user/createUser`;
  static acceptUser = `${environment.base_url}/user/approve`;
  static rejectUser = `${environment.base_url}/user/reject`;

  static createAdminUser = `${environment.base_url}/masterUser/createAdminUser`;
  static getAdminUserList = `${environment.base_url}/masterUser/getAdmins`;

  static getCustomerVerification = `${environment.base_url}/verification/getVerifications`;
  static addCustomerVerification = `${environment.base_url}/verification/initialize`;
  static userVerificationURL = `${environment.base_url}/verification/getPublicUrlForVerification/`;

  static countryList = `${environment.base_url}/countries`;

  static getMasterApplicationList = `${environment.base_url}/application/getApplications`;
  static createMasterApplication = `${environment.base_url}/application`;
  static createVerificationTier = `${environment.base_url}/verificationTier`;
  static createDocumentCategory = `${environment.base_url}/documentCategory/addDocumentCategory`;

  static getMasterApplicationGroupList = `${environment.base_url}/getAllApplicationGroups`;
  static createMasterApplicationGroup = `${environment.base_url}/applicationGroup`;

  static linkApplicationGroups = `${environment.base_url}/linkApplicationGroups`;

  static getUserLogList = `${environment.base_url}/user/getLogs`;
  static dateRangeType = `${environment.base_url}/enums/dateRangeType`;
  static getApiForUserLogs = `${environment.base_url}/user/getAllApi`;

  static sendMail = `${environment.base_url}/trustedDevice/sendVerificationMailAgain`;
  static getResendOtpDelaySeconds = `${environment.base_url}/otp/getResendOtpDelaySeconds`;

  static getSubUserList = `${environment.base_url}/getSubUsers`;
  static createSubUser = `${environment.base_url}/user/createSubUser`;

  static getAccessRole = `${environment.base_url}/user/accessRole`;
  static addAccessRole = `${environment.base_url}/user/accessRole/add`;
  static deleteAccessControlPolicy = `${environment.base_url}/user/accessControlPolicy/delete`;
  static getAccessControlByAccessRoleId = `${environment.base_url}/accessControl/forAccessRole`;
  static addAccessControlPolicy = `${environment.base_url}/user/accessControlPolicy/add`;
  static getAccessRoleByAccessRoleId = `${environment.base_url}/user/accessRole/byId`;
  static updateColumnsForAccessRole = `${environment.base_url}/user/accessRole/updateFilterColumns`;
  static getEntityNames = `${environment.base_url}/entity`;
  static getClassStructureForEntity = `${environment.base_url}/entity/getClassStructure`;

  static getAllNotificationConfig = `${environment.base_url}/notificationConfig/getAllConfigs`;
  static updateNotificationConfig = `${environment.base_url}/notificationConfig/update`;
  static notificationBannerType = `${environment.base_url}/enums/notificationBannerType`;
  static notificationBannerDestination = `${environment.base_url}/enums/notificationBannerDestination`;
  static getConfigById = `${environment.base_url}/notificationConfig/getById`;

  static getDirectBillingList = `${environment.base_url}/user/billing/getBillings`;
  static createDirectBilling = `${environment.base_url}/user/billing`;
  static getChildrenBillingList = `${environment.base_url}/user/billing/getGrandBillings`;
  static createChildrenBilling = `${environment.base_url}/user/billing/children`;
  static getBillingLog = `${environment.base_url}/user/billing/getLog`;
  static getGrandBillingLog = `${environment.base_url}/user/billing/getGrandLog`;

  static getDocumentList = `${environment.base_url}/document/getAll`;
  static documentUploadByUrl = `${environment.base_url}/document/upload`;
  static getPublicUrlByDocumentId = `${environment.base_url}/document/getPublicUrl`;
  static getDocumentApproveByDocumentId = `${environment.base_url}/document/approve`;
  static getDocumentRejectByDocumentId = `${environment.base_url}/document/reject`;
  static getDocumentCategory = `${environment.base_url}/documentCategory`;

  static userType = `${environment.base_url}/enums/userType`;
  static getBillingType = `${environment.base_url}/enums/billingType`;
  static getBillingStatus = `${environment.base_url}/enums/billingStatus`;
  static getVerificationTierLevel = `${environment.base_url}/enums/verificationTierLevel`;
  static getVerificationExpiryTime = `${environment.base_url}/enums/timeUnit`;
  static getDocumentExpiry = `${environment.base_url}/enums/documentExpiryAction`;
  static getDocumentStatus = `${environment.base_url}/enums/documentStatus`;

  static twoFactorInit = `${environment.base_url}/twoFactor/init`;
  static validateTwoFactorOTP = `${environment.base_url}/twoFactor/validateTwoFactorOTP`;
  static setUpTwoFactor = `${environment.base_url}/twoFactor/setUpTOtp`;
  static isTOTPSetUpDone = `${environment.base_url}/twoFactor/isTOTPSetUpDone`;
  static initiateSetupTwoFactor = `${environment.base_url}/twoFactor/initiateSetupTwoFactor`;

  static blockJwtToken = `${environment.base_url}/blockJwtToken`;

  static getCustomers = `${environment.base_url}/getCustomers`;
  static createCustomer = `${environment.base_url}/user/createCustomer`;
  static getProfileCustomer = `${environment.base_url}/user/getProfile`;
  static getProfileUser = `${environment.base_url}/user/getProfile`;
  static getVerificationType = `${environment.base_url}/enums/verificationType`;
  static getvideocerificationStatus = `${environment.base_url}/enums/videoVerificationStatus`;
  static getlivenessStatus = `${environment.base_url}/enums/livenessStatus`;
  static getApprovalType = `${environment.base_url}/enums/verificationApprovalType`;
  static getUserVerificationCheckStatus = `${environment.base_url}/enums/userVerificationCheckStatus`;

  static getVerificationUploadUrl = `${environment.base_url}/verification/getUploadUrl`;
  static verificationUploadDone = `${environment.base_url}/verification/uploadDone`;
  static getVerificationStatus = `${environment.base_url}/verification/getVerificationStatus`;
  static livenessComplete = `${environment.base_url}/verification/livenessComplete`;
  static userVerificationStatus = `${environment.base_url}/verification/updateStatus`;
  static getStatusStepList = `${environment.base_url}/verification/getStatusStepList`;
  static getVerificationDetails = `${environment.base_url}/verification/getVerificationDetails`;
  static userCreationDefaults = `${environment.base_url}/userCreationDefaults`;
  static updateVerificationParams = `${environment.base_url}/updateVerificationParams`;
  static cleanAWSCognito = `${environment.base_url}/masterUser/cleanAWSCognito`;
  static reCreateVerificationLink = `${environment.base_url}/verification/reCreateVerificationLink`;
}
